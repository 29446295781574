import React, { useContext } from "react";
import { Menu, Dropdown } from "semantic-ui-react";

import { AuthContext } from "../../shared/context/auth-context";
import { Link } from "react-router-dom";

const MobileNavbar = (props) => {

    const { admin, logout } = useContext(AuthContext);

    return (
        <Menu inverted>
            <Menu.Item>
                {admin.email} - 
                <p style={{ color: "red", marginLeft: "5px" }}>
                    {
                        admin.role_id === 1 ? "ADMIN" : "OPS Team"
                    }
                </p>
            </Menu.Item>

            <Menu.Menu position="right">
                <Dropdown item icon='bars' simple>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            as={Link}
                            to="/"
                        >
                            Statistics
                        </Dropdown.Item>
                        <Dropdown.Item
                            as={Link}
                            to="/orders"
                        >
                            Orders
                        </Dropdown.Item>
                        <Dropdown.Item
                            as={Link}
                            to="/blogs"
                        >
                            Blogs
                        </Dropdown.Item>
                        <Dropdown.Item
                            as={Link}
                            to="/new-blog"
                        >
                            Create Blog
                        </Dropdown.Item>
                        <Dropdown.Item
                            as={Link}
                            to="/settings"
                        >
                            Settings
                        </Dropdown.Item>
                        <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Menu>
        </Menu>
    );
}

export default MobileNavbar;