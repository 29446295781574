import React, { useContext } from "react";
import { Grid } from "semantic-ui-react";
import { ToastContainer } from "react-toastify";
import { withRouter } from "react-router";

import Navbar from "../components/Navbar/Navbar";
import MobileNavbar from "../components/Navbar/MobileNavbar";

import { AuthContext } from "../shared/context/auth-context";
import { ThemeContext } from "../shared/context/theme-context";

import "react-toastify/dist/ReactToastify.css";
import "./Layout.scss";

const Layout = (props) => {

    const auth = useContext(AuthContext);
    const { theme } = useContext(ThemeContext);

    let bodyWidth = "16";

    if (auth.isLoggedIn && theme.isComputer) {
        bodyWidth = "13";
    } else if (auth.isLoggedIn && theme.isComputer) {
        bodyWidth = "16";
    }

    return (
        <div className="Layout">
            <ToastContainer  autoClose={1500} />

            {
                auth.isLoggedIn && theme.isMobile &&
                <div className="Layout--navbar">
                    <MobileNavbar />
                </div>
            }

            <Grid>
                <Grid.Column width="3">
                    {
                        auth.isLoggedIn && theme.isComputer &&
                        <div className="Layout--navbar">
                            <Navbar />
                        </div>
                    }
                </Grid.Column>
                <Grid.Column width={bodyWidth}>
                    <main className="Layout--body">
                        {props.children}
                    </main>
                </Grid.Column>
            </Grid>
            

        </div>
    );
}

export default withRouter(Layout);