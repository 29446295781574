import React, { useContext } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { Loader } from "semantic-ui-react";

import Layout from "./layout/Layout";

import { AuthContext } from "./shared/context/auth-context";

const Login = React.lazy(() => import("./pages/Login/Login"));
const Stats = React.lazy(() => import("./pages/Stats/Stats"));
const Settings = React.lazy(() => import("./pages/Settings/Settings"));
const Blogs = React.lazy(() => import("./pages/Blogs/Blogs"));
const CreateBlog = React.lazy(() => import("./pages/CreateBlog/CreateBlog"));
const Orders = React.lazy(() => import("./pages/Orders/Orders"));

const App = (props) => {

    const { isLoggedIn } = useContext(AuthContext);

    let allowedRoutes = (
        <Switch>
            <Route exact path="/" component={Login} />
        </Switch>
    );
    
    if (isLoggedIn) {
        
        allowedRoutes = (
            <Switch>
                <Route exact path="/" component={Stats} />
                <Route exact path="/settings" component={Settings} />
                <Route exact path="/blogs" component={Blogs} />
                <Route exact path="/new-blog" component={CreateBlog} />
                <Route exact path="/orders" component={Orders} />
            </Switch>
        );
    }

    return (
        <Layout>
            <React.Suspense fallback={<Loader size="massive" active />}>
                {allowedRoutes}
            </React.Suspense>
        </Layout>
    );
}

export default withRouter(App);
