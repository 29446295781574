import React, { useContext } from "react";
import { Menu, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { AuthContext } from "../../shared/context/auth-context";

import "./Navbar.scss";

const Navbar = () => {

    const { admin, logout } = useContext(AuthContext);

    return (
        <div className="fixed-menu">
            <div style={{ flex: 1, overflowY: "scroll" }}>
                <Menu 
                    style={{ borderRadius: "0px", height: "100%" }} 
                    fluid 
                    inverted 
                    vertical 
                    borderless 
                    compact
                >
                    <Menu.Item>
                        <Menu.Header>
                            {admin.email}
                            <p style={{ color: "red", marginTop: "5px" }}>
                                {
                                    admin.role_id === 1 ? "ADMIN" : "OPS Team"
                                }
                            </p>
                        </Menu.Header>
                    </Menu.Item>
                    <Menu.Item 
                        as={Link}
                        to="/"
                        icon="chart bar"
                        content="Statistics"
                    />
                    <Menu.Item 
                        as={Link}
                        to="/orders"
                        icon="shop"
                        content="Orders"
                    />
                    <Menu.Item 
                        as={Link}
                        to="/blogs"
                        icon="book"
                        content="Blogs"
                    />
                    <Menu.Item 
                        as={Link}
                        to="/new-blog"
                        icon="pencil"
                        content="Create Blog"
                    />
                    <Menu.Item 
                        as={Link}
                        to="/settings"
                        icon="settings"
                        content="Settings"
                    />
                    
                </Menu>
            </div>
            <div style={{ flex: "0 0 auto", padding: "20px" }}>
                <Button fluid negative onClick={logout}>LOGOUT</Button>
            </div>
        </div>
    );
}

export default Navbar;